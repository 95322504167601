import React from "react";
import CarteImage from "./carteimage";
import cx from "../Cartes/c9.jpg";
import cy from "../Cartes/c7.jpg";

class GridImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cimg: Array(12).fill(null),
      combinaison: Array(12).fill(null),
      step: 0,
      carte: Array(2).fill(null),
      actif: true,
      seconds: 0,
      win:false,
    };

    this.state.combinaison = this.InitCombinaison();
  }

  InitCombinaison() {
    var i, j, tmp, decalage, max;
    max = 15;
    decalage = Math.floor(Math.random() * Math.floor(max));
    const tabtmp = Array(12).fill(null);
    for (let i = 0; i < tabtmp.length / 2; i++) {
      tabtmp[i] = 1 + ((decalage + i) % max);
      tabtmp[i + 6] = 1 + ((decalage + i) % max);
    }
    for (i = tabtmp.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      tmp = tabtmp[i];
      tabtmp[i] = tabtmp[j];
      tabtmp[j] = tmp;
    }

    return tabtmp;
  }

  isWinner() {
    let win = true;
    const timg = this.state.cimg.slice();

    for (let i = 0; i < timg.length; i++) {
      win = win && timg[i] != null;
    }
    if (win) {
      console.log("GAGGGGGGNNNEEE");

      clearInterval(this.timerID);
      this.setState({
        win: true,
      });
    }
  }

  tick() {
      this.isWinner();
    this.setState({
      seconds: this.state.seconds + 1,
    });
  }

  startTimer() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  handleClick(i) {
    if (this.state.cimg[i] === null && this.state.actif) {
      this.setState({
        actif: false,
      });

      if (!this.timerID) {
        this.startTimer();
      }

      const timg = this.state.cimg.slice();
      timg[i] = require("../Cartes/c" +
        this.state.combinaison[i] +
        ".jpg").default;

      if (this.state.step <= 1) {
        this.state.carte[this.state.step] = i;
        this.state.step++;

        if (this.state.step === 2) {
          if (
            this.state.combinaison[this.state.carte[0]] ===
            this.state.combinaison[this.state.carte[1]]
          ) {
            // Les cartes correspondent on réinitialise

            this.setState({
              step: 0,
              carte: Array(2).fill(null),
              actif: true,
            });
          } else {
            // Les cartes ne correspondent pas on réinitialise et on retourne les cartes

            setTimeout(() => {

              timg[this.state.carte[0]] = null;
              timg[this.state.carte[1]] = null;
              this.setState({
                step: 0,
                actif: true,
                carte: Array(2).fill(null),
              });
            }, 1000);
          }
        } else {
          this.setState({
            actif: true,
          });
        }
      }

      this.setState({
        cimg: timg,
      });
    } else {
      console.log("deja cliquée");
    }

  }
  renderTime() {
      let message = (this.state.win)?"Gagné en "+this.state.seconds+" secondes":"Temps : "+this.state.seconds;
    return (
      <p>{message}</p>
    );
  }

  renderImage(i) {
    return (
      <CarteImage
        id={i}
        win={this.state.win}
        url={this.state.cimg[i]}
        onClick={() => this.handleClick(i)}
      />
    );
  }

  render() {

    return (
      <div>
        <div>{this.renderTime()}</div>
        <div className="board-row">
          {this.renderImage(0)}
          {this.renderImage(1)}
          {this.renderImage(2)}
        </div>
        <div className="board-row">
          {this.renderImage(3)}
          {this.renderImage(4)}
          {this.renderImage(5)}
        </div>
        <div className="board-row">
          {this.renderImage(6)}
          {this.renderImage(7)}
          {this.renderImage(8)}
        </div>
        <div className="board-row">
          {this.renderImage(9)}
          {this.renderImage(10)}
          {this.renderImage(11)}
        </div>
      </div>
    );
  }
}

export default GridImages;
