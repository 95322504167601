import React from "react";
import cx from '../Cartes/cx.jpg';
class CarteImage extends React.Component {


  render() {
    let srcimg = (this.props.url==null)?cx:this.props.url;
    let classname = (this.props.win)?"carteimage win":"carteimage";
    return <img className={classname}
      id={this.props.id}
      src={srcimg}
      onClick={this.props.onClick}
    />

  }
}

export default CarteImage;
