import React from "react";
import GridImages from "./gridimages";


class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      timeseconde: 0,
    };

  }



  render(){

    return (

      <div className="game">
      <div className="game-info">
      
      </div>
        <div className="game-board">
          <GridImages

            onClick={(i) => this.handleClick(i)}
          />
        </div>

      </div>
    );
  }

}

  export default Game;
