import CarteImage from "./Components/carteimage";

import Game from "./Components/game";

import logo from "./logo.svg";
import c1 from "./Cartes/c1.jpg";

import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>My-Expresso.com</p>
      </header>


        <Game url={c1} />
    </div>
  );
}

export default App;
